<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import newsItem from "@/components/news-item/news-item"
import Sort from "@/components/sort"
import {
  checkFromData,
  formatRegionNewsList,
  cleanUpData,
  formatImportNews,
  RegionSettingSchema
} from '@/views/pages/marketing-slot/home-index/common.js'
import FastImport from '@/components/FastImport'

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "快訊跑馬燈",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "快訊跑馬燈",
          active: true,
        }
      ],
      fromInfo: [],
      showSort: false,
      saving: false,
      releasing: false
    };
  },
  mounted () {
    this.getAreaSet(14)
  },
  components: {
    Layout,
    PageHeader,
    newsItem,
    Sort,
    FastImport
  },
  methods: {
    importArticleList (_news) {
      console.log('=> 接收自動帶入的新聞清單: ', _news)
      this.fromInfo = _news.map(_article => {
        return formatImportNews(_article)
      })
    },
    // NewsItem 操作
    addNewOne () {
      this.fromInfo.push({ ...RegionSettingSchema })
    },
    deleteOne (_index) {
      this.fromInfo.splice(_index, 1)
    },
    setItemData (_setItem, _index) {
      this.fromInfo.splice(_index, 1, _setItem)
    },
    // 獲取區域設置
    getAreaSet (_regionId) {
      this.fromInfo = []
      this.$root.apis.getAreaSetting(_regionId, this.getAreaSetSuccess, this.onAPIError)
    },
    getAreaSetSuccess (_response) {
      this.fromInfo = formatRegionNewsList(_response.body.data.news)
    },
    // 請求更新分區資料
    saveRegionData () {
      const vm = this
      if (!checkFromData(vm, this.fromInfo, 14)) {
        return
      }

      let setData = {
        items: cleanUpData(this.fromInfo)
      }
      this.saving = true
      this.$root.apis.saveAreaSetting(14, setData, this.processSaveSuccess, this.processSaveError)
    },
    processSaveSuccess () {
      this.$root.common.showSingleNotify("儲存成功！")
      this.saving = false
    },
    processSaveError (_error) {
      this.$root.common.showErrorNotify(_error.body.data)
      this.saving = false
    },
    // 請求發布分區資料
    releaseAreaSetting () {
      this.releasing = true
      this.$root.apis.releaseAreaSetting(14, this.processReleaseSuccess, this.processReleaseError)
    },
    processReleaseSuccess () {
      this.$root.common.showSingleNotify("發布成功！")
      this.releasing = false
    },
    processReleaseError (_error) {
      this.$root.common.showErrorNotify(_error.body.data)
      this.releasing = false
    },
    onSort (_sorted) {
      this.fromInfo = _sorted
      this.showSort = false
    }
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  Sort(:show-sort="showSort" :data-list="fromInfo" @sort="onSort")
  FastImport(
    :region-type="14"
    :show-sub-type="false" 
    :show-max-count="true"
    @import="importArticleList"
  )
  .row
    .col
      b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="showSort = true") 編輯排序
    .col-lg-12
      .card.col-lg-9(v-for="(item,index) of fromInfo")
        newsItem(
          :key="`region14-NewsItemList-${index}${item.newsId}`"
          :canUseAd="false"
          :showImage="false"
          :regionId="14"
          :item="item" 
          :index="index" 
          @setItemData="setItemData"
          @removeItem="deleteOne"
        )
      .row
        .col
          b-button.col-lg-9.add-btn.width-lg.mb-2(v-if="fromInfo.length < 10" variant="outline-primary" @click="addNewOne") ＋新增一則 （最多10則） 
      .row.mt-2
        .col
          b-button.action-btn.width-lg.mr-1(variant="primary" @click="saveRegionData" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
          b-button.action-btn.width-lg(variant="primary" @click="releaseAreaSetting" :disabled="releasing") {{ releasing ? '發布中...' : '發布' }}
</template>
<style lang="sass" scoped>
.row
  .card
    margin-bottom: 2rem
    .form-group
      display: flex
      justify-content: space-start
      align-items: center
      flex-wrap: wrap
      gap: 14px
      .search-box
        position: relative
        #tip
          padding: 0 10px 0 35px
          background-repeat: no-repeat
          background-position: 10px 7px
        .fa-search
          position: absolute
          top: 30%
          margin: auto
      .del-btn
        flex: 1
        display: flex
        justify-content: flex-end
        align-items: center
        .btn
          padding: 0rem .3rem
          border-radius: 50px
  .add-btn
    width: 100%
    border: dashed 1px
  .btn-box
    margin-top: 3rem
    .fileupload
      margin-top: 1rem
      margin-bottom: 1rem
      padding: 1rem 3rem
      border: 1px solid #ccc
@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
    margin: 0.5rem 0
</style>
